<template>
  <div class="information">
    <div class="title">
      <span style="">基本信息</span>
      <div>
        <i class="el-icon-printer" style="font-size.5rem;color"></i>
        打印
      </div>
    </div>
    <div class="content">

      <el-form :label-position="labelPosition" :rules='rules' label-width="170px" :model="formLabelAlign"
        ref="formLabelAlign" style="display: flex; flex-wrap: wrap">
        <el-form-item label="老人头像:" style="width: 39%">
          <el-avatar shape="square" :size="100" :fit="fit" :src="formLabelAlign.photo"></el-avatar>
        </el-form-item>
        <el-form-item style="width: 49%">
          <el-form-item label="老人姓名:" prop="oldName" style="width: 100%; margin-top: 1rem">
            <el-input v-model="formLabelAlign.oldName" placeholder="请填写老人姓名"></el-input>
          </el-form-item>
          <el-form-item label="证件类型:" prop="idLei" style="width: 100%; margin-top: 1rem">
            <el-select v-model="formLabelAlign.idLei" placeholder="请选择证件类型">
              <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="身份证号:" prop="idCard" style="width: 100%; margin-top: 1rem">
            <el-input v-model="formLabelAlign.idCard" placeholder="请输入身份证号"></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item label="老人分类:" prop="oldType" style="width: 49%">
          <el-select v-model="formLabelAlign.oldType" placeholder="请选择老人分类">
            <el-option key="分散自理" label="分散自理" value="分散自理"> </el-option>
            <el-option key="分散半自理" label="分散半自理" value="分散半自理"></el-option>
            <el-option key="低保农村300" label="低保农村300" value="低保农村300"></el-option>
            <el-option key="低保农村450" label="低保农村450" value="低保农村450"></el-option>
            <el-option key="低保农村600" label="低保农村600" value="低保农村600"></el-option>
            <el-option key="低保城市450" label="低保城市450" value="低保城市450"></el-option>
            <el-option key="低保城市675" label="低保城市675" value="低保城市675"></el-option>
            <el-option key="低保城市900" label="低保城市900" value="低保城市900"></el-option>
            <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="出生日期:" style="width: 49%" prop="date">
          <el-date-picker type="date" placeholder="选择出生日期" v-model="formLabelAlign.date1"></el-date-picker>
        </el-form-item>
        <el-form-item label="性别:" style="width: 49%" prop="sex">
          <el-select v-model="formLabelAlign.sex" placeholder="请选择性别">
            <el-option key="男" label="男" value="男"> </el-option>
            <el-option key="女" label="女" value="女"></el-option>
            <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="年龄:" prop="age" style="width: 49%">
          <el-input v-model="formLabelAlign.age" placeholder="请输入年龄"></el-input>
        </el-form-item>
        <el-form-item label="民族:" style="width: 49%" prop="idLei">
          <el-select v-model="formLabelAlign.idLei" placeholder="请选择民族">
            <el-option key="汉" label="汉" value="汉"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone" style="width: 49%">
          <el-input v-model="formLabelAlign.phone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="行政区划:" prop="area" style="width: 49%">
          <el-cascader v-model="formLabelAlign.area" :options="options" @change="handleBaseValue"
            :props="optionProps"></el-cascader>
        </el-form-item>
        <!-- <el-form-item label="通过社区（村）快速选择:" style="width: 49%">
                            <el-select v-model="formLabelAlign.idLei" placeholder="请选择">

                            </el-select>
                        </el-form-item> -->
        <el-form-item label="居住地址:" prop="address" style="width: 49%">
          <el-input v-model="formLabelAlign.address" placeholder="请输入居住地址"></el-input>
          <div style="color:#DCDFE6"> 格式：xx市区xx区xx街道xx小区xx楼xx单元xx室</div>
        </el-form-item>
        <el-form-item label="生存状态:" prop="status" style="width: 49%">
          <el-input v-model="formLabelAlign.status" placeholder="请输入生存状态"></el-input>
        </el-form-item>

        <el-form-item label="是否常住人口:" style="width: 49%" prop="isResident">
          <el-select v-model="formLabelAlign.isResident" placeholder="请选择是否常住人口">
            <el-option key="是" label="是" value="是"> </el-option>
            <el-option key="否" label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本市户籍:" style="width: 49%" prop="isLocal">
          <el-select v-model="formLabelAlign.isLocal" placeholder="请选择本市户籍">
            <el-option key="是" label="是" value="是"> </el-option>
            <el-option key="否" label="否" value="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="户籍地址:" style="width: 49%" prop="householdAddress">
          <el-input v-model="formLabelAlign.householdAddress" placeholder="请输入户籍地址"></el-input>
        </el-form-item>
        <el-form-item label="户口性质:" style="width: 49%" prop="householdType">
          <el-select v-model="formLabelAlign.householdType" placeholder="请选择户口性质">
            <el-option key="农业户口" label="农业户口" value="农业户口"> </el-option>
            <el-option key="非农业户口" label="非农业户口" value="非农业户口"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文化程度:" style="width: 49%" prop="culture">
          <el-select v-model="formLabelAlign.culture" placeholder="请选择文化程度">
            <el-option key="文盲半文盲" label="农业户口" value="农业户口"> </el-option>
            <el-option key="小学" label="非农业户口" value="非农业户口"></el-option>
            <el-option key="初中/中专" label="非农业户口" value="非农业户口"></el-option>
            <el-option key="高中" label="非农业户口" value="非农业户口"></el-option>
            <el-option key="大学专科" label="非农业户口" value="非农业户口"></el-option>
            <el-option key="大学本科" label="非农业户口" value="非农业户口"></el-option>
            <el-option key="研究生" label="非农业户口" value="非农业户口"></el-option>
            <el-option key="博士生及以上" label="非农业户口" value="非农业户口"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="兴趣爱好:" prop="name" style="width: 100%">
                            <div class="hobby">
                                <el-checkbox-group v-model="formLabelAlign.interest">
                                    <el-checkbox label="京剧"></el-checkbox>
                                    <el-checkbox label="书法"></el-checkbox>
                                    <el-checkbox label="唱歌"></el-checkbox>
                                    <el-checkbox label="茶道"></el-checkbox>
                                    <el-checkbox label="园艺"></el-checkbox>
                                    <el-checkbox label="太极"></el-checkbox>
                                    <el-checkbox label="广场舞"></el-checkbox>
                                    <el-checkbox label="游泳"></el-checkbox>
                                    <el-checkbox label="象棋"></el-checkbox>
                                    <el-checkbox label="二胡"></el-checkbox>
                                    <el-checkbox label="扑克"></el-checkbox>
                                    <el-checkbox label="民族舞"></el-checkbox>
                                    <el-checkbox label="钓鱼"></el-checkbox>
                                    <el-checkbox label="登山"></el-checkbox>
                                    <el-checkbox label="旅游"></el-checkbox>
                                    <el-checkbox label="猫狗"></el-checkbox>
                                    <el-checkbox label="麻将"></el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div>其他：</div>
                            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="formLabelAlign.textarea">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="助老服务需求:" prop="name" style="width: 100%">
                            <div class="hobby">
                                <el-checkbox-group v-model="formLabelAlign.interest">
                                    <el-checkbox label="助餐"></el-checkbox>
                                    <el-checkbox label="助医"></el-checkbox>
                                    <el-checkbox label="助洁"></el-checkbox>
                                    <el-checkbox label="助浴"></el-checkbox>
                                    <el-checkbox label="助乐"></el-checkbox>
                                    <el-checkbox label="助急"></el-checkbox>
                                    <el-checkbox label="助购"></el-checkbox>
                                    <el-checkbox label="助聊"></el-checkbox>
                                    <el-checkbox label="助行"></el-checkbox>
                                    <el-checkbox label="助学"></el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-form-item> -->
        <el-form-item label="备注:" prop="textarea" style="width: 100%">
          <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="formLabelAlign.textarea" style="width: 80rem">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "YanglaoJbxx",
  props: {
    dataProps: {
      type: Object,
      default: ''
    }
  },
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        oldName: this.dataProps.result.oldName || '',
        idLei: this.dataProps.result.idType || '',
        idCard: this.dataProps.result.idCard || '',
        oldType: this.dataProps.result.oldType || '',
        sex: this.dataProps.result.sex == '1' ? '男' : '女',
        age: this.dataProps.result.age || '',
        phone: this.dataProps.result.phone || '',
        areaName: this.dataProps.result.areaName || '',
        address: this.dataProps.result.address || '',
        status: this.dataProps.result.status || '',
        isResident: this.dataProps.result.isResident || '',
        isLocal: this.dataProps.result.isLocal || '',
        householdAddress: this.dataProps.result.householdAddress || '',
        householdType: this.dataProps.result.householdType || '',
        culture: this.dataProps.result.culture || '',
        id: this.dataProps.result.id || '',
        idCard: this.dataProps.result.idCard || '',
        interest: this.dataProps.result.interest || [],
        photo: this.dataProps.result.filePath || ''
      },
      imageUrl: "",
      rules: {
        oldName: [
          { required: true, message: '请填写老人姓名', trigger: 'blur' },
        ],
        idLei: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        oldType: [
          { required: true, message: '请选择老人分类', trigger: 'change' },
        ],
        date1: [
          { required: true, message: '请选择日期', trigger: 'change' },
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ],
        oldType: [
          { required: true, message: '请选择老人分类', trigger: 'change' },
        ],
        date: [
          { required: true, message: '请选择出生日期', trigger: 'change' },
        ],
        sex: [
          { required: true, message: '请选择出生日期', trigger: 'change' },
        ],
        age: [
          { required: true, message: '请输入年龄', trigger: 'blur' },
        ],
        area: [
          { required: true, message: '请选择行政区划', trigger: 'change' },
        ],
        idLei: [
          { required: true, message: '请选择民族', trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入居住地址', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请输入生存状态', trigger: 'blur' },
        ],
        isResident: [
          { required: true, message: '请是否常住人口', trigger: 'change' },
        ],
        isLocal: [
          { required: true, message: '请选择本市户籍', trigger: 'change' },
        ],
        householdAddress: [
          { required: true, message: '请输入户籍地址', trigger: 'blur' },
        ],
        householdType: [
          { required: true, message: '请选择户口性质', trigger: 'change' },
        ],
        culture: [
          { required: true, message: '请选择文化程度', trigger: 'change' },
        ],
      },

    };
  },

  mounted() {
    setTimeout(() => {
      console.log(99999, this.dataProps), 500
    })
  },

  methods: {
    interestClick(e) {
      this.formLabelAlign.interest = e
      console.log(this.interest)
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  border: 1px solid #ccced1;
}

.avatar-uploader .el-upload {
  border: 1px dashed white;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 1rem;
  color: #8c939d;
  width: 10rem;
  height: 10rem;
  line-height: 10rem;
  text-align: center;
}

.avatar {
  width: 10rem;
  height: 10rem;
  display: block;
}

.el-input {
  width: 30rem;
}

.el-select {
  width: 30rem !important;
}

.el-checkbox {
  margin-right: 2px;
}

.el-cascader {
  width: 30rem;
}

.hobby {
  width: 100%;
  border: 1px solid #d5d5d5;
  padding: 0 5px;
}

.title {
  width: 98%;
  border-bottom: 1px solid #999;
  padding-bottom: 0.5rem;
  margin: 1rem auto;
  font-size: 1rem;
  color: rgb(50, 50, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>